const { default: Api } = require("../../helpers/Api");

export const addNewRoleContact = async (obj) => {
    try {
        const res = await Api.post("wallet/relation/add/contact/new-role", obj);
        const data = res?.data || {};
        return data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const getContactDetailsById = async (obj) => {
    try {
        const res = await Api.post("wallet/relation/get/contact/by-id", obj);
        const data = res?.data || {};
        return data;
    } catch (error) {
        console.log(error);
        return null;
    }
};


export const getContactDetailsByTypeAndBothProfile = async (obj) => {
    try {
        const res = await Api.post("wallet/relation/get/contact/by-type-and-both-profile", obj);
        const data = res?.data || {};
        return data;
    } catch (error) {
        console.log(error);
        return null;
    }
};


export const getProjectOwnerByProjectId = async (obj) => {
    try {
        const res = await Api.post("get/project/owner/profile-data", obj);
        const data = res?.data || {};
        return data;
    } catch (error) {
        console.log(error);
        return null;
    }
};