import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Api from "../../helpers/Api";
import CommentCreate from "./comment.create";
import CommentUpdate from "./comment.update";
import CommentCard from "./comment.card";
import arrayToReducer from "../../helpers/arrayToReducer";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    flex: 1,
    marginBottom: "1rem",
    marginTop: "1rem",
    ...(props || {}),
  }),
  marginTop: {
    flex: 1,
    marginTop: "1rem",
  },
}));

const SmartCommentList = ({
  parent,
  parentModelName,
  limit = -1,
  commentAdded,
  styleBody,
  sentProfile,
  guestView,
}) => {
  const [editMode, setEditMode] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const classes = useStyles(styleBody);
  const dispatch = useDispatch();

  const [comments, setComments] = useState([]);
  const [commentIds, setCommentIds] = useState([]);
  const [commentDictionary, setCommentDictionary] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getComments();
  }, []);

  const getComments = async () => {
    setLoading(true);

    const commentRequestObj = {
      parent,
      model: parentModelName,
    };
    if (limit >= 0) commentRequestObj.limit = limit;

    await Api.post("comment/getComments", commentRequestObj).then((cmnts) => {
      setComments(cmnts);
      console.log(cmnts,' are comments')
    });

    setLoading(false);
  };

  useEffect(() => {
    const { newDict, idArr } = arrayToReducer(comments);
    setCommentIds(idArr);
    setCommentDictionary(newDict);
  }, [comments?.length]);

  useEffect(() => {}, [commentIds?.length]);

  return (
    <div className={classes.root}>
      {editMode ? (
        <CommentUpdate
          commentId={selectedId}
          setEditMode={setEditMode}
          commentDictionary={commentDictionary}
          setCommentIds={setCommentIds}
          setCommentDictionary={setCommentDictionary}
          sentProfile={sentProfile}
        />
      ) : (
        <CommentCreate
          setCommentIds={setCommentIds}
          setCommentDictionary={setCommentDictionary}
          parent={parent}
          commentAdded={commentAdded}
          parentModelName={parentModelName}
          sentProfile={sentProfile}
          guestView={guestView}
        />
      )}
      <div className={classes.marginTop}>
        {!loading ? (
          commentIds &&
          commentIds?.length > 0 &&
          commentIds.map((commentId) => {
            const comment = commentDictionary[commentId];
            if (comment && comment?._id) {
              return (
                <CommentCard
                  key={comment._id}
                  commentId={commentId}
                  comment={comment}
                  setEditMode={setEditMode}
                  setSelectedId={setSelectedId}
                  setCommentDictionary={setCommentDictionary}
                  sentProfile={sentProfile}
                />
              );
            }
          })
        ) : (
          <div
            style={{
              width: "100%",
              height: "50px",
              marginTop: "1rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </div>
        )}
      </div>
    </div>
  );
};

export default SmartCommentList;

// import React, { useEffect, useRef, useState } from "react";
// import { useSelector, useDispatch } from "react-redux";
// import { makeStyles, useTheme } from "@material-ui/core/styles";
// import Api from "../../helpers/Api";
// import CommentCreate from "./comment.create";
// import CommentUpdate from "./comment.update";
// import CommentCard from "./comment.card";
// import arrayToReducer from "../../helpers/arrayToReducer";
// import CircularProgress from "@material-ui/core/CircularProgress";

// const useStyles = makeStyles((theme) => ({
//   root: (props) => ({
//     flex: 1,
//     marginBottom: "1rem",
//     marginTop: "1rem",
//     ...(props || {}),
//   }),
//   marginTop: {
//     flex: 1,
//     marginTop: "1rem",
//   },
// }));

// const SmartCommentList = ({
//   parent,
//   parentModelName,
//   limit = -1,
//   commentAdded,
//   styleBody,
//   sentProfile,
//   guestView,
// }) => {
//   const [editMode, setEditMode] = useState(false);
//   const [selectedId, setSelectedId] = useState(null);
//   const classes = useStyles(styleBody);
//   const dispatch = useDispatch();

//   const [comments, setComments] = useState([]);
//   const [commentIds, setCommentIds] = useState([]);
//   const [commentDictionary, setCommentDictionary] = useState({});
//   const [loading, setLoading] = useState(false);

//   useEffect(() => {
//     getComments();
//   }, []);

//   const getComments = async () => {
//     setLoading(true);

//     const commentRequestObj = {
//       parent,
//       model: parentModelName,
//     };
//     if (limit >= 0) commentRequestObj.limit = limit;

//     await Api.post("comment/getComments", commentRequestObj).then((cmnts) => {
//       setComments(cmnts);
//     });

//     setLoading(false);
//   };

//   useEffect(() => {
//     const { newDict, idArr } = arrayToReducer(comments);
//     setCommentIds(idArr);
//     setCommentDictionary(newDict);
//   }, [comments?.length]);

//   useEffect(() => {}, [commentIds?.length]);

//   return (
//     <div className={classes.root}>
//       {editMode ? (
//         <CommentUpdate
//           commentId={selectedId}
//           setEditMode={setEditMode}
//           commentDictionary={commentDictionary}
//           setCommentIds={setCommentIds}
//           setCommentDictionary={setCommentDictionary}
//           sentProfile={sentProfile}
//         />
//       ) : (
//         <CommentCreate
//           setCommentIds={setCommentIds}
//           setCommentDictionary={setCommentDictionary}
//           parent={parent}
//           commentAdded={commentAdded}
//           parentModelName={parentModelName}
//           sentProfile={sentProfile}
//           guestView={guestView}
//         />
//       )}
//       <div className={classes.marginTop}>
//         {!loading ? (
//           commentIds &&
//           commentIds?.length > 0 &&
//           commentIds.map((commentId) => {
//             const comment = commentDictionary[commentId];
//             if (comment && comment?._id) {
//               return (
//                 <CommentCard
//                   key={comment._id}
//                   commentId={commentId}
//                   comment={comment}
//                   setEditMode={setEditMode}
//                   setSelectedId={setSelectedId}
//                   setCommentDictionary={setCommentDictionary}
//                   sentProfile={sentProfile}
//                 />
//               );
//             }
//           })
//         ) : (
//           <div
//             style={{
//               width: "100%",
//               height: "50px",
//               marginTop: "1rem",
//               display: "flex",
//               alignItems: "center",
//               justifyContent: "center",
//             }}
//           >
//             <CircularProgress />
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

// export default SmartCommentList;
