import React, { useEffect } from "react";
import { ConnectedRouter } from "connected-react-router";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { Switch, Route, useHistory, useLocation } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import { CssBaseline, useMediaQuery } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { firebaseLoginHelper } from "./components/auth/auth.utils";
import { useFeedData } from "./components/explore/feed.hooks";
import { useGetTeams } from "./components/team/team.hooks";
import { useGetUserNotification } from "./components/activity/activity.hooks";
import BasicPrivateRoute from "./components/PrivateRoute/BasicPrivateRoute";
import AuthPage from "./components/auth/Auth";
import firebase from "firebase";
import GuestRoute from "./components/GuestRoute/GuestRoute";
import ApprovalRoutes from "./routes/approval.routes.js";

// import BookingRoutes from "./routes/booking.routes";
// import ProfileChat from "./components/profileChat/index";
// import Projects from "./components/projects/projects";
// import DashboardRoutes from "./routes/dashboard.routes";
// import ExploreRoutes from "./routes/explore.routes";
// import TeamRoutes from "./routes/team.routes";
// import BlogRoutes from "./routes/blog.routes";
// import AccountingRoutes from "./routes/accounting.routes";
// import Account from "./components/account/account";
// import InvitationLink from "./components/team/invitation.link.handler";
// import Subscription from "./components/finance2o/checkout/subscription/index";
// import CheckoutBackdrop from "./components/finance2o/checkout/checkoutBackdrop";
import theme from "./utils/theme";
import Api from "./helpers/Api";
import Backdrop from "./components/styled/backdrop.style";
import useProfile from "./components/profile/useProfile";
import Landing from "./components/landing2/index";
import useSocketHook from "./helpers/socket/useSocketHook";
// import Organizations from "./components/organization/organizations";
import Layout from "./components/layout";
// import RentalRelationView from "./components/ProjectAnalysis/rental.relation.view";
import NotificationAlert from "./components/activity/NotificationAlert";
// import PayBackTx from "./components/finance2o/commonComponent/payBackTx";
// import PaymentSuccess from "./components/styled/CommonComponents/PaymentSuccess.js";
// import ForgotPassword from "./components/auth/ForgotPassword";
// import ResetPassword from "./components/auth/ResetPassword";
// import AuthBox from "./components/auth/AuthBox";
// import TermsAndConditions from "./components/landing2/termsAndConditions/TermAndConditions";
// import Privacy from "./components/landing2/termsAndConditions/Privacy";
// import CommunityRules from "./components/landing2/termsAndConditions/CommunityRules";
import ApiAlert from "./components/styled/alert/api.alert";
// import DwollaPaymentStatusPage from "./components/styled/CommonComponents/DwollaPaymentStatusPage";
// import PortfolioHome from "./components/Portfolio/index";
// import LandingInvestmentAnalysis from "./components/landing2/investmentAnalysis/index";
// import SharePageRouter from "./components/PrivateRoute/SharePageRoute";
// import GuestReportView from "./components/ProjectAnalysis/GuestReportView";
// import GuestLayoutHeader from "./components/Navbar/GuestLayout.Header";
// import OrgView from "./components/organization/OrgView";
// import ReportCompare from "./components/ProjectAnalysis/Report.Compare.js/ReportCompare";
// import ProjectHome from "./components/team/Project.Home";
import ScrollToTop from "./components/styled/CommonComponents/ScrollTop";
import Error404 from "./components/ErrorPages/Error404";
import Error403 from "./components/ErrorPages/Error403";
// import OrgHome from "./components/team/Org.Home";
// import Marketplace from "./components/marketplace";
// import ProjectViewHome from "./components/team/Project.View.Home";
import "./components/styled/scroll.css";
// import ProjectEstimation from "./components/team/Project.Estimation";
// import ProjectLibrary from "./components/team/Project.Library";
// import SiteManagement from "./components/sitemanagement/SiteManagement";
// import WorkSchedule from "./components/team/WorkSchedule/WorkSchedule";
// import Procurement from "./components/team/procurements/Procurement";
// import OpsOrder from "./components/team/procurements/OpsOrder";
// import ProjectLibraryView from "./components/team/Project.Library.View";
// import Inventory from "./components/team/inventory/Inventory";
// import GRN from "./components/team/procurements/GRN";
// import FinanceRelationView from "./components/FinanceRelationView";
// import MarketplaceExplore from "./components/marketplace/MarketplaceExplore.jsx";
// import SiteReport from "./components/sitereport/SiteReport.jsx";
// import EditSiteReport from "./components/sitereport/EditSiteReport.jsx";
// import ViewSiteReport from "./components/sitereport/ViewSiteReport.jsx";
// import HR from "./components/hr/HR.jsx";
// import HRSettings from "./components/hr/settings/HRSettings.jsx";
//import MyWork from "./components/mywork/MyWork.jsx";
// import EditEmployee from "./components/hr/employees/EditEmployee.jsx";
import AccessControlStoreHook from "./components/AccessControl/AccessControlStoreHook.js";
// import BulkAddEmployees from "./components/hr/employees/BulkAddEmployees.jsx";
// import PayrollChecks from "./components/hr/payroll/payrollcheck/PayrollChecks.jsx";
// import PaySlipTemplateEdit from "./components/hr/settings/finance/payslip/PaySlipTemplateEdit.jsx";
// import EmployeeAbout from "./components/hr/employees/about/EmployeeAbout.jsx";
// import EndEmployeeContract from "./components/hr/employees/endcontract/EndEmployeeContract.jsx";
import { getAuthCookieData, setAuthCookieData } from "./helpers/cookie.helper";

const App = (props) => {
  const Auth = firebase.auth();
  const dispatch = useDispatch();
  const themeMI = useTheme();
  const state = useSelector((state) => state);
  const { auth, appGlobal } = state;
  const { user, isFromSignup } = auth;
  const { backdropVisible } = appGlobal;
  localStorage.setItem("activeUserId", user?._id);
  localStorage.setItem("activeUserProfileId", user?.profile);
  const profileId = user?.profile;
  const isMobile = useMediaQuery(themeMI?.breakpoints.down("xs"));
  const history = useHistory();
  const location = useLocation();
  const pathname = location["pathname"];
  const oldDisplayName = user?.displayName;

  // const { getParticipantAccess, checkAccess } = AccessControlStoreHook();
  let currentUrl = window.location.href;

  // useEffect(() => {
  //   getParticipantAccess();
  //   let cookieData = getAuthCookieData(dispatch);
  //   console.log(cookieData, " is stored in cookie");
  // }, []);

  useEffect(() => {
    const currentPath = location.pathname;
    if (currentPath === '' || currentPath === '/') {
      window.location.href = 'https://reallist.ai/';
    }
  }, [location]);


  useEffect(() => {
    const initAuth = async () => {
      try {
        const { user, token, entireState } = await getAuthCookieData(dispatch);
        if (entireState) {
          console.log("Entire state retrieved successfully");
        } else {
          console.warn("No entire state retrieved");
        }
      } catch (error) {
        console.error("Error initializing auth:", error);
      }
    };
    if (!user?._id) {
      initAuth();
    }
  }, []);
  

  // useEffect(() => {
  //   if (user?._id) {
  //     if (
  //       user?.wallet
  //     ) {
  //       console.log("all good with the profile");
  //     } else {
  //       let path = "/pre-login/";
  //       history.push(path);

  //     }
  //   }
  // }, [user?._id]);


  // useGetUserNotification();
  // useFeedData();
  // useProfile();
  // useGetTeams();

  // useEffect(() => {
  //   dispatch({
  //     type: "AddChat",
  //     payload: {
  //       convIds: [],
  //       convDict: {},
  //       msgMap: {},
  //       msgDict: {},
  //     },
  //   });
  // }, []);

  // async function testAuth() {
  //   const fireBaseUser = await firebase.auth();
  //   if (user && user.error && fireBaseUser && fireBaseUser.currentUser)
  //     fireBaseUser.currentUser
  //       .getIdToken(/* forceRefresh */ true)
  //       .then(async (idToken) => {
  //         // Send token to your backend via HTTPS
  //         localStorage.setItem("token", idToken);
  //       })
  //       .catch(function (error) {
  //         // Handle error
  //         console.error("Firebase : ", error);
  //       });
  //   firebaseLoginHelper(fireBaseUser?.currentUser, dispatch);
  // }

  // useEffect(() => {
  //   dispatch({ type: "FileUploadReset" });

  //   if (Auth.currentUser) {
  //     if (!user?._id) {
  //       const currentUser = Auth.currentUser;
  //       firebaseLoginHelper(currentUser, dispatch);
  //     }
  //   }
  //   testAuth();
  // }, [Auth.currentUser]);

  //to store user data in localStorage
  useEffect(async () => {
    if (user?._id) {
      localStorage.setItem("activeUserId", user?._id);
      localStorage.setItem("activeUserProfileId", user?.profile);
 
    }
  }, [user?._id]);

  // useEffect(() => {
  //   OneSignal.init({
  //     appId: "dd8e5c00-523b-4735-b07d-2cd4af497f58"
  //   });
  // }, []);

  // useEffect(() => {
  //   if (user && user._id) {
  //     Api.post("invite/getUserInvites", { user: [user.profile] }).then(
  //       (res) => {
  //         const invitations = res.invitations;
  //         console.log(res);

  //         dispatch({
  //           type: "AddTeam",
  //           payload: {
  //             invitations,
  //           },
  //         });
  //       }
  //     );
  //   }
  // }, [user]);

  return (
    <MuiThemeProvider theme={theme}>
      <ThemeProvider theme={theme}>
        <ConnectedRouter history={props.history}>
          <ScrollToTop>
            <CssBaseline />
            <Switch>
      
              {ApprovalRoutes()}


              <GuestRoute exact path="/login" component={AuthPage} />
              <GuestRoute exact path="/signup" component={AuthPage} />
        
              {/* <Route exact path="/user/subscription">
                <Subscription />
              </Route> */}
              {/* <Route exact patTh="/invitation/:invitationId">
                <InvitationLink />
              </Route>
              <Route exact path="/forgot-password">
                <ForgotPassword />
              </Route>
              <Route exact path="/reset-password">
                <ResetPassword />
              </Route>
              <Route exact path="/authenticate">
                <AuthBox />
              </Route>
              <Route exact path="/terms">
                <TermsAndConditions />
              </Route> */}
              {/* <Route exact path="/investment/analysis/new">
                <LandingInvestmentAnalysis />
              </Route> */}
              {/* <Route exact path="/privacy">
                <Privacy />
              </Route>
              <Route exact path="/rules">
                <CommunityRules />
              </Route> */}
              <GuestRoute exact path="/about" component={Landing} />
              <BasicPrivateRoute path="/403" component={Error403} />
              <BasicPrivateRoute path="*" component={Error404} />
            </Switch>
            <Backdrop open={backdropVisible} />
            {/* <CheckoutBackdrop /> */}
          </ScrollToTop>
        </ConnectedRouter>
        <NotificationAlert />
        <ApiAlert />
      </ThemeProvider>
    </MuiThemeProvider>
  );
};

export default App;
