import React, { useEffect, useState } from "react";
import Button from "../styled/generic/Button";
import { Add } from "@mui/icons-material";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import { IconButton, Paper, Grid } from "@mui/material";
import LinearProgress from "@material-ui/core/LinearProgress";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import Api from "../../helpers/Api";
import ApprovalTempleteCreateDrawer from "./Approval.Templete.Create.Drawer";
import { getProfileData } from "../team/procurements/api.call";
import { getApprovalTemplateById, getApprovalTemplates } from "./api.call";
import ApprovalTemplateCard from "./ApprovalTemplateCard";
import { updateLibraryById } from "../OfferingsAndProducts/Api.call";
import { approvalTypesDefaultFieldsMap, getLinerStatuses } from "../../helpers/approval";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ApprovalRequests from "./Approval.Requests";
import { TextField, InputAdornment, Avatar, ClickAwayListener } from "@material-ui/core";
import TuneIcon from '@material-ui/icons/Tune';
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100vh",
    backgroundColor: "white",
    overflow: "hidden",
  },
  backArrow: {
    fontSize: "30px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px",
    },
  },
  bodyCont: {
    width: "100%",
    height: "calc(100vh - 55px)",
    overflowX: "hidden",
    overflowY: "auto",
    [theme.breakpoints.down("sm")]: {
      padding: "10px 10px 50px",
    },
  },
  topBar: {
    width: "100%",
    height: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid #dfdfdf",
    paddingRight: "20px",
  },
  barLeft: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  barRight: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& .MuiButton-label": {
      textTransform: "capitalize",
    },
  },
  headerTitle: {
    fontSize: "20px",
    fontWeight: "550",
    marginLeft: "10px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "15px",
      fontWeight: "510",
      marginLeft: "0px",
    },
  },
  templateCardCont: {
    width: "100%",
    height: "100%",
    overflowY: "auto",
    padding: "20px"
  },
  tabMainCont: {
    width: "100%",
    height: "calc(100% - 50px)",
  },
  filterCont: {
    display: "block",
    position: "absolute",
    width: "500px",
    top: "45px",
    right: "35px",
    padding: "15px",
    backgroundColor: "white",
    borderRadius: "5px",
    zIndex: "999",
    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
    transition: "all 0.3s",
  },
  hideFilterCont: {
    display: "none",
    position: "absolute",
    width: "500px",
    top: "45px",
    right: "35px",
    padding: "15px",
    backgroundColor: "white",
    borderRadius: "5px",
    zIndex: "999",
    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
    transition: "all 0.3s",
  }
}));

const ApprovalHome = ({ }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { user } = useSelector((state) => state.auth);
  const { profileId } = useParams();
  const [walletData, setWalletData] = useState(null);
  const [activeTab, setActiveTab] = useState("To Review");
  const [approvalTemplates, setApprovalTemplates] = useState([]);
  const [requestsToReview, setRequestsToReview] = useState([]);
  const [requestsToReviewLoading, setRequestsToReviewLoading] = useState(false);
  const [createTempleteDawer, setCreateTempleteDrawer] = useState(false);
  const [profileData, setProfileData] = useState(null);
  const [curEditDeleteTemplete, setCurEditDeleteTemplete] = useState(null);
  const [loader, setLoader] = useState(false);
  const [buildingCodeLibrary, setBuildingCodeLibrary] = useState(null);
  const [curOption, setCurOption] = useState(0);
  const [showFilter, setShowFilter] = useState(false)
  const [filterObj, setFilterObj] = useState({
    searchQuery: "",
    status: [],
    type: ""
  })

  const getTemplates = async () => {
    try {
      setLoader(true);
      await getApprovalTemplates({ libraryId: walletData?.defaultLibrary })
        .then((data) => {
          console.log(data)
          setBuildingCodeLibrary(data?.buildingCodeLibrary)
          setApprovalTemplates(data?.approvalTemplates || []);
        })
        .catch((err) => {
          console.log("Error: ", err);
          dispatch({
            type: "AddApiAlert",
            payload: {
              success: false,
              message: "Unable to fetch approval templates",
            },
          });
        });
    } catch (error) {
      console.log("Error: ", error);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to fetch approval templates",
        },
      });
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (profileId) {
      getProfileData({ profileId })
        .then((data) => {
          console.log(data);
          const walletData = data?.parent?.wallet || null;
          setWalletData(walletData);
          setProfileData(data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [profileId]);

  useEffect(() => {
    if (walletData?.defaultLibrary) {
      getTemplates();
    }
  }, [walletData]);

  const afterTempleteCreateOrEdit = () => {
    getTemplates();
  };

  const onApprovalEditBtnClick = async (templateId) => {
    setLoader(true);
    setCreateTempleteDrawer(true);
    await getApprovalTemplateById({ templateId })
      .then((data) => {
        console.log(data);
        setCurEditDeleteTemplete(data);
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };

  //have to check----------------------------------------------------------
  const createRequest = async (templateId) => {
    try {
      const { data } = await Api.post("/approval/request/create", {
        createdBy: user?.profile,
        templateId,
      });
      if (data) {
        history.push(`/approval/request/${data?._id}`);
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: true,
            message: "Request created successfully",
          },
        });
      } else {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: false,
            message: "Unable to create request",
          },
        });
      }
    } catch (error) {
      console.log("Error: ", error);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to create request",
        },
      });
    }
  };

  const getRequestsToBeReviewedByMe = async () => {
    try {
      setRequestsToReviewLoading(true);
      const { data } = await Api.post(
        "/approval/request/get-to-be-reviewed-by-me",
        {
          profileId,
        }
      );
      if (data) {
        setRequestsToReview(data);
      }
    } catch (error) {
      console.log("Error: ", error);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to fetch approval requests",
        },
      });
    } finally {
      setRequestsToReviewLoading(false);
    }
  };

  useEffect(() => {
    if (activeTab === "To Review") {
      getRequestsToBeReviewedByMe();
    }
  }, [activeTab]);

  const updateDefault = async (type, templateId) => {
    let updateObj = {
      _id: buildingCodeLibrary?._id,
      reason: "toUpdateDefaultApprovalTemplate",
      myProfile: profileData?._id
    }
    updateObj[approvalTypesDefaultFieldsMap[type]] = templateId
    setLoader(true)
    await updateLibraryById(updateObj)
      .then((data) => {
        console.log(data)
        setBuildingCodeLibrary(data)
        setLoader(false)
      })
      .catch((err) => {
        console.log(err)
        setLoader(false)
      })
  }

  const getStatusesByType = (type) => {
    switch (type) {
      case "Material Request":
      case "Bill Of Quantities":
      case "Purchase Order":
      case "Work Order":
      case "GRN":
        return getLinerStatuses(type);

      case "Budget Cost":
        return getLinerStatuses("Estimation");

      case "Invoice":
      case "Bill":
        return getLinerStatuses("Transaction");

      case "DebitNote":
      case "CreditNote":
        let arr1 = getLinerStatuses("Transaction");
        let arr2 = getLinerStatuses("Debit/Credit Note");
        let setArr = new Set([...arr1, ...arr2]);
        return [...setArr];

      case "Invoice Template":
      case "Bill Template":
        return getLinerStatuses("Transaction Template");

      case "Financial Certificate":
        return getLinerStatuses("Financial Certificate");

      case "Site Observation":
        return getLinerStatuses("Site Observation");

      case "Task":
        return getLinerStatuses("WBS");
      
      default:
        return [];
    }
  }

  return (
    <div className={classes.root}>
      <div className={classes.topBar}>
        <div className={classes.barLeft}>
          <IconButton
            className={classes.iconButtonStyle}
            onClick={() => {
              history.goBack();
            }}
          >
            <KeyboardBackspaceIcon className={classes.backArrow} />
          </IconButton>
          <h3 className={classes.headerTitle}>Approval Management</h3>
        </div>
        <div className={classes.barRight}>
          {curOption === 0 && (
            <ClickAwayListener
              onClickAway={() => {
                setShowFilter(false)
              }}
            >
              <div>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  placeholder="Search..."
                  value={filterObj.searchQuery}
                  style={{ width: "300px", marginRight: "10px" }}
                  onChange={(e) => {
                    setFilterObj({
                      ...filterObj,
                      searchQuery: e.target.value
                    })
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setShowFilter(!showFilter)
                        }}
                      >
                        <TuneIcon />
                      </InputAdornment>
                    ),
                  }}
                />
                <div className={showFilter ? classes.filterCont : classes.hideFilterCont} >
                  <Grid container spacing={2} gridColumn={12}>
                    <Grid item xs={6} >
                      <Autocomplete
                        value={filterObj?.type}
                        options={[
                          "Material Request",
                          "Bill Of Quantities",
                          "Purchase Order",
                          "Work Order",
                          "GRN",
                          "Budget Cost",
                          "Invoice",
                          "Bill",
                          "DebitNote",
                          "CreditNote",
                          "Invoice Template",
                          "Bill Template",
                          "Financial Certificate",
                          "Site Observation",
                          "Task"
                        ]}
                        getOptionLabel={(option) => { return option || "" }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            style={{ margin: "0px" }}
                            margin="normal"
                            variant="outlined"
                            label="Type"
                            placeholder="Select type"
                          />
                        )}
                        onChange={(e, value) => {
                          setFilterObj({
                            ...filterObj,
                            type: value,
                            status: []
                          })
                        }}
                        fullWidth
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={6} >
                      <Autocomplete
                        value={filterObj?.status}
                        options={filterObj?.type ? getStatusesByType(filterObj?.type) : []}
                        getOptionLabel={(option) => { return option || "" }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            style={{ margin: "0px" }}
                            margin="normal"
                            variant="outlined"
                            label="Status"
                            placeholder="Select status"
                          />
                        )}
                        multiple={true}
                        disabled={filterObj?.type ? false : true}
                        onChange={(e, value) => {
                          setFilterObj({
                            ...filterObj,
                            status: value
                          })
                        }}
                        fullWidth
                        size="small"
                      />
                    </Grid>
                  </Grid>
                </div>
              </div>
            </ClickAwayListener>
          )}
          <Button
            variant="contained"
            color="primary"
            startIcon={<Add />}
            onClick={() => setCreateTempleteDrawer(true)}
          >
            New Approval
          </Button>
          <ApprovalTempleteCreateDrawer
            afterTempleteCreateOrEdit={afterTempleteCreateOrEdit}
            curEditDeleteTemplete={curEditDeleteTemplete}
            createTempleteDawer={createTempleteDawer}
            setCreateTempleteDrawer={setCreateTempleteDrawer}
            profileData={profileData}
            loader={loader}
            setLoader={setLoader}
            buildingCodeLibrary={buildingCodeLibrary}
          />
        </div>
      </div>
      <div style={{ width: "100%", height: "5px" }}>
        {loader && <LinearProgress />}
      </div>
      <div className={classes.bodyCont}>
        <div>
          <Tabs
            value={curOption}
            onChange={(event, newValue) => { setCurOption(newValue); }}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab label="Requests" />
            <Tab label="Templates" />
          </Tabs>
        </div>
        <Paper elevation={2} className={classes.tabMainCont}  >
          {curOption === 0 && (
            <ApprovalRequests
              profileId={profileId}
              filterObj={filterObj}
              setLoader={setLoader}
            />
          )}
          {curOption === 1 && (
            <div className={classes.templateCardCont}>
              <Grid container spacing={2}>
                {approvalTemplates &&
                  approvalTemplates.map((approvalTemplate) => (
                    <Grid item xs={12} md={6} lg={4} spacing={2}>
                      <ApprovalTemplateCard
                        approvalTemplate={approvalTemplate}
                        onApprovalEditBtnClick={onApprovalEditBtnClick}
                        buildingCodeLibrary={buildingCodeLibrary}
                        updateDefault={updateDefault}
                      />
                    </Grid>
                  ))}
              </Grid>
            </div>
          )}
        </Paper>
      </div>
    </div>
  );
};

export default ApprovalHome;
