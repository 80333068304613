import { Box } from "@mui/material";
import React from "react";

const StandardAppContainerRounded = ({
  sx,
  fullHeight,
  children,
  ...props
}) => {
  return (
    <Box
      sx={{
        p: 3,
        paddingTop: "10px",
        backgroundColor: sx?.backgroundColor || "#FFFFFF",
        borderRadius: sx?.borderRadius || "10px",
        border: sx?.border || "1px solid #F3F3F3",
        boxShadow: sx?.boxShadow || "0px 0px 80px 0px rgba(171, 190, 209, 0.15)",
        minHeight: fullHeight ? "80vh" : "auto",
        ...sx,
      }}
      {...props}
    >
      {children}
    </Box>
  );
};

export default StandardAppContainerRounded;
