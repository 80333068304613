import React, { useState, useEffect } from "react";
import { AsyncPaginate } from "react-select-async-paginate";
import { makeStyles } from "@material-ui/core/styles";
import { components } from "react-select";
import { Avatar } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    optionsMainCont: {
        display: "flex",
        alignItems: "center",
        "& img": {
            width: "30px",
            height: "30px",
            borderRadius: "50%"
        },
        "& h5": {
            fontSize: "13px",
            fontWeight: "500",
            marginBottom: "-1"
        },
        "& p": {
            fontSize: "10px",
            fontWeight: "400",
            color: "black !important"
        },
    }
}))

const CustomSelectAutocomplete = ({
    value, onChange, isMulti = false, options = [], disabled = false,
    style, placeholder, getLabel, getIdentity, getImage, getDisplayName,
    getUsername, fullWidth
}) => {
    const classes = useStyles();
    const [restructuredValue, setRestructuredValue] = useState();

    const loadOptions = async (search, loadedOptions, { page }) => {
        const curOptions = options ? options.map((s) => {
            return {
                value: getLabel(s),
                label: getIdentity(s),
                data: s
            }
        }) : []

        return {
            options: curOptions,
            hasMore: false,
            additional: {
                page: page + 1,
            },
        };
    };

    useEffect(() => {
        if (isMulti) {
            setRestructuredValue(value ? value.map((s) => {
                return {
                    value: getLabel(s),
                    label: getIdentity(s),
                    data: s
                }
            }) : [])
        } else {
            setRestructuredValue(value ? {
                value: getLabel(value),
                label: getIdentity(value),
                data: value
            } : undefined)
        }
    }, [value])

    const SingleOption = ({ image, name, username }) => {
        return (
            <div className={classes.optionsMainCont} >
                <Avatar
                    src={image}
                    alt={name}
                    style={{ width: "25px", height: "25px" }}
                />
                <div style={{ paddingLeft: "10px" }} >
                    <h5>{name}</h5>
                    <p>{username}</p>
                </div>
            </div>
        );
    }

    const CustomOption = (props) => {
        return (
            <components.Option {...props}>
                <SingleOption
                    image={getImage(props?.data?.data)}
                    name={getDisplayName(props?.data?.data)}
                    username={getUsername(props?.data?.data)}
                />
            </components.Option>
        );
    };

    return (
        <AsyncPaginate
            value={restructuredValue}
            onChange={(value) => {
                if (isMulti) {
                    onChange(value ? value.map((s) => s?.data) : [])
                } else {
                    onChange(value?.data)
                }
            }}
            isMulti={isMulti}
            loadOptions={loadOptions}
            isDisabled={disabled}
            style={style ? style : fullWidth ? { width: "100%" } : {}}
            placeholder={placeholder}
            getOptionLabel={(option) => (
                <SingleOption
                    image={getImage(option?.data)}
                    name={getDisplayName(option?.data)}
                    username={getUsername(option?.data)}
                />
            )}
            additional={{
                page: 0,
            }}
            components={{
                Option: CustomOption
            }}
        />
    );
};

export default CustomSelectAutocomplete;