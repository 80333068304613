import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CustomSelectAutocomplete from "../CommonComponents/CustomSelectAutocomplete";

const ProjectPicker = ({
  selectedProject,
  defaultProject,
  setSelectedProject,
  defaultProjectId,
  updateTask = "",
  fullWidth = false,
  disabled = false,
  isMulti = false,
}) => {
  const [projects, setProjects] = useState([]);
  const { teamDictionary, teamIds } = useSelector((state) => state.team);

  const setUpProjects = () => {
    // Collect all projects first
    const projectsList = [];
    for (let i = 0; i < teamIds.length; i++) {
      let id = teamIds[i];
      let team = teamDictionary[id];
      if (team?.parent?.model == "Project") {
        projectsList.push(team?.parent);
      }
    }
    // Set all projects at once
    setProjects(projectsList);
  };

  useEffect(() => {
    setUpProjects();
  }, [teamIds, teamDictionary]);

  const handleDefaultSelection = () => {
    let dfProject = null;

    if (projects?.length > 0 && defaultProjectId) {
      let index = projects.findIndex((item) => item?._id == defaultProjectId);
      if (index != -1) {
        dfProject = projects[index];
      }
    } else if (projects?.length > 0 && defaultProject && defaultProject?._id) {
      let index = projects.findIndex(
        (item) => item?._id == defaultProject?._id
      );
      if (index != -1) {
        dfProject = projects[index];
      }
    } else {
      if (!selectedProject) {
        dfProject = null;
      }
    }
    if (isMulti) {
      if (dfProject) {
        setSelectedProject([dfProject]);
      } else {
        if (!selectedProject || !Array.isArray(selectedProject)) {
          setSelectedProject([]);
        }
      }
    } else {
      if (dfProject) {
        setSelectedProject(dfProject);
      } else {
        setSelectedProject();
      }
    }
  };

  useEffect(() => {
    if (projects.length > 0) {
      handleDefaultSelection();
    }
  }, [projects, defaultProjectId, defaultProject, selectedProject, isMulti]);

  return (
    <Box>
      <CustomSelectAutocomplete
        value={selectedProject}
        onChange={(value) => {
          setSelectedProject(value);
        }}
        isMulti={isMulti}
        options={projects}
        disabled={disabled}
        fullWidth={fullWidth}
        style={{ width: "100%" }}
        placeholder={"Select Project"}
        getLabel={(s) => s?.displayName}
        getIdentity={(s) => s?._id}
        getImage={(s) => s?.displayPicture?.url}
        getDisplayName={(s) => s?.displayName}
        getUsername={(s) => s?.username}
      />
    </Box>
  );
};

export default ProjectPicker;
